import React, { useState, useLayoutEffect } from 'react'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'
import { motion } from 'framer-motion'
import { useInView } from 'react-hook-inview'

const StyledShadowBox = styled.div`
  position: relative;
  width: 522px;
  /* height: 452px; */

  @media screen and (max-width: 576px) {
    height: unset;
  }
`

const Box = styled(motion.div)`
  background-color: ${props => props.theme.color.face.light};
  height: 100%;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  padding: 60px;

  @media screen and (max-width: 484px) {
    padding: 30px;
  }

  h2 {
    font-size: ${props => props.theme.font.size.xml};
    font-weight: ${props => props.theme.font.weight.xl};
    padding-bottom: 30px;
  }

  p {
    font-size: ${props => props.theme.font.size.sm};
  }
`

const Shadow = styled(motion.div)`
  background: ${props => props.theme.color.gradient.main};
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  border-radius: 20px;
`

const ShadowBox = ({ delay, className, content }) => {
  const [ref, isVisible] = useInView({
    threshold: 0.5,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if(isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  return (
    <StyledShadowBox ref={ref} className={`mx-lg-0 mx-5 ${className ? `${className}` : ``}`}>
      <Shadow
        initial={{ left: 0, top: 0 }}
        animate={isVisible ? { left: -50, top: -50 } : {}}
        transition={{ delay: delay || 0, type: 'spring', duration: .4 }}
        className="d-lg-block d-none"
      />
      <Shadow
        initial={{ left: 0, top: 0 }}
        animate={isVisible ? { left: -25, top: -25 } : {}}
        transition={{ delay: delay || 0, type: 'spring', duration: .4 }}
        className="d-lg-none d-block"
      />
      <Box
        className="d-flex align-items-center"
      >
        <div>
          {parse(content.description)}
        </div>
      </Box>
    </StyledShadowBox>
  )
}

export default ShadowBox